export default [
  {
    component: 'CNavItem',
    name: 'Dashboard',
    to: '/dashboard',
    icon: 'cil-speedometer',
  },
  {
    component: 'CNavTitle',
    name: 'Gestion RH',
  },
  {
    component: 'CNavGroup',
    name: 'Employé',
    to: '/employe',
    icon: 'cil-people',
    items: [
      {
        component: 'CNavItem',
        name: 'Liste',
        to: '/employe/liste',
      },
      {
        component: 'CNavItem',
        name: 'Nouveau',
        to: '/employe/nouveau',
      },
    ],
  },
  {
    component: 'CNavGroup',
    name: 'Absence / Congé',
    to: '/absence',
    icon: 'cil-people',
    items: [
      {
        component: 'CNavItem',
        name: 'Liste',
        to: '/absence/liste',
      },
      {
        component: 'CNavItem',
        name: 'Nouveau',
        to: '/absence/nouveau',
      },
    ],
  },
  {
    component: 'CNavGroup',
    name: 'Fiche',
    to: '/fiche',
    icon: 'cil-notes',
    items: [
      {
        component: 'CNavItem',
        name: 'Liste',
        to: '/fiche/liste',
      },
    ],
  },
  // admin
  {
    component: 'CNavTitle',
    name: 'Administration',
  },
  {
    component: 'CNavGroup',
    name: 'CNSS',
    to: '/cnss',
    icon: 'cil-cc',
    items: [
      {
        component: 'CNavItem',
        name: 'Liste',
        to: '/cnss/liste',
      },
      {
        component: 'CNavItem',
        name: 'Nouveau',
        to: '/cnss/nouveau',
      },
    ],
  },
  {
    component: 'CNavGroup',
    name: 'Taxe',
    to: '/taxe',
    icon: 'cil-short-text',
    items: [
      {
        component: 'CNavItem',
        name: 'Liste',
        to: '/taxe/liste',
      },
      {
        component: 'CNavItem',
        name: 'Nouveau',
        to: '/taxe/nouveau',
      },
    ],
  },
  {
    component: 'CNavGroup',
    name: 'Paramètre',
    to: '/parametre',
    icon: 'cil-cog',
    items: [
      {
        component: 'CNavItem',
        name: 'Liste',
        to: '/parametre/liste',
      },
      {
        component: 'CNavItem',
        name: 'Nouveau',
        to: '/parametre/nouveau',
      },
    ],
  },
  {
    component: 'CNavGroup',
    name: 'Utilisateur',
    to: '/user',
    icon: 'cil-user',
    items: [
      {
        component: 'CNavItem',
        name: 'Liste',
        to: '/user/liste',
      },
      {
        component: 'CNavItem',
        name: 'Nouveau',
        to: '/user/nouveau',
      },
    ],
  },

  // rapports
  {
    component: 'CNavTitle',
    name: 'Rapports et Stats',
  },
  {
    component: 'CNavItem',
    name: 'Livre de paie',
    to: '/rapport/livre-de-paie',
    icon: 'cil-book',
  },
  {
    component: 'CNavItem',
    name: 'Charges sociales',
    to: '/rapport/charges-sociales',
    icon: 'cil-medical-cross',
  },
  {
    component: 'CNavItem',
    name: 'Charges fiscales',
    to: '/rapport/charges-fiscales',
    icon: 'cil-note-add',
  },
  {
    component: 'CNavItem',
    name: 'Masse salariale',
    to: '/rapport/masse-salariale',
    icon: 'cil-chart',
  },
]
