import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import CoreuiVue from '@coreui/vue-pro'
import CIcon from '@coreui/icons-vue'
import { iconsSet as icons } from '@/assets/icons'
import DocsCallout from '@/components/DocsCallout'
import DocsExample from '@/components/DocsExample'
/* import {
  KEYCLOAK_CLIENT,
  KEYCLOAK_REALM,
  KEYCLOAK_URL,
} from './_helpers/constants' */
// import { vueKeycloak } from '@josempgon/vue-keycloak'

const app = createApp(App)
/* const options = {
  config: {
    url: KEYCLOAK_URL,
    realm: KEYCLOAK_REALM,
    clientId: KEYCLOAK_CLIENT,
    resource: 'rh-be',
  },
  initOptions: {
    flow: 'standard',
    onLoad: 'login-required',
    checkLoginIframe: false,
  },
} */

/* async () => {
  await vueKeycloak.install(app, options) */
app.use(store)
app.use(router)
app.use(CoreuiVue)
app.provide('icons', icons)
app.component('CIcon', CIcon)
app.component('DocsCallout', DocsCallout)
app.component('DocsExample', DocsExample)

app.mount('#app')
//}
