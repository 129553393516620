import { h, resolveComponent } from 'vue'
import { createRouter, createWebHashHistory } from 'vue-router'
import { authGuard } from '@/_helpers/auth-guard'
import DefaultLayout from '@/layouts/DefaultLayout'

const routes = [
  {
    path: '/',
    name: 'Accueil',
    component: DefaultLayout,
    redirect: '/dashboard',
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue'),
      },

      // employe
      {
        path: '/employe',
        name: 'Employé',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/employe/liste',
        children: [
          {
            path: '/employe/liste',
            name: 'Liste des employés',
            component: () => import('@/views/Employe/employes.vue'),
          },
          {
            path: '/employe/nouveau',
            name: 'Nouveau',
            component: () => import('@/views/Employe/employe-new.vue'),
          },
          {
            path: '/employe/:id/edit',
            name: 'Editer un employé',
            component: () => import('@/views/Employe/employe-new.vue'),
            props: true,
          },
          {
            path: '/employe/:id',
            name: "Détails de l'employé",
            component: () => import('@/views/Employe/employe-details.vue'),
            props: true,
          },
        ],
      },

      // absence
      {
        path: '/absence',
        name: 'Absence / Congé',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/absence/liste',
        children: [
          {
            path: '/absence/liste',
            name: "Liste des autorisations d'absence",
            component: () => import('@/views/Absence/absences.vue'),
          },
          {
            path: '/absence/nouveau',
            name: 'Nouvel absence',
            component: () => import('@/views/Absence/absence-new.vue'),
          },
          {
            path: '/absence/:id/edit',
            name: 'Editer une absence',
            component: () => import('@/views/Absence/absence-new.vue'),
            props: true,
          },
          {
            path: '/absence/:id',
            name: 'Supprimer une absence',
            component: () => import('@/views/Absence/absence-details.vue'),
            props: true,
          },
        ],
      },

      // parametre
      {
        path: '/parametre',
        name: 'Paramètre',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/parametre/liste',
        children: [
          {
            path: '/parametre/liste',
            name: 'Liste des paramètres',
            component: () => import('@/views/Parametre/parametres.vue'),
          },
          {
            path: '/parametre/nouveau',
            name: 'Nouveau paramètre',
            component: () => import('@/views/Parametre/parametre-new.vue'),
          },
          {
            path: '/parametre/:id/edit',
            name: 'Editer un paramètre',
            component: () => import('@/views/Parametre/parametre-new.vue'),
          },
        ],
      },

      // taxe
      {
        path: '/taxe',
        name: 'Taxe',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/taxe/liste',
        children: [
          {
            path: '/taxe/liste',
            name: 'Liste des taxes',
            component: () => import('@/views/Taxe/taxes.vue'),
          },
          {
            path: '/taxe/nouveau',
            name: 'Nouvelle taxe',
            component: () => import('@/views/Taxe/taxe-new.vue'),
          },
          {
            path: '/taxe/:id/edit',
            name: 'Editer une taxe',
            component: () => import('@/views/Taxe/taxe-new.vue'),
          },
          {
            path: '/taxe/:id',
            name: "Détails d'une taxe",
            component: () => import('@/views/Taxe/taxe-details.vue'),
          },
        ],
      },

      // user
      {
        path: '/user',
        name: 'Utilisateur',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/user/liste',
        children: [
          {
            path: '/user/liste',
            name: 'Liste des utilisateurs',
            component: () => import('@/views/User/users.vue'),
          },
          {
            path: '/user/:id/profil',
            name: 'Profil',
            component: () => import('@/views/User/user-profil.vue'),
          },
          {
            path: '/user/nouveau',
            name: 'Nouvel utilisateur',
            component: () => import('@/views/User/user-new.vue'),
          },
          {
            path: '/user/:id/edit',
            name: 'Editer un utilisateur',
            component: () => import('@/views/User/user-new.vue'),
          },
          {
            path: '/user/:id',
            name: "Détails d'un utilisateur",
            component: () => import('@/views/User/user-details.vue'),
          },
        ],
      },

      // cnss
      {
        path: '/cnss',
        name: 'CNSS',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/cnss/liste',
        children: [
          {
            path: '/cnss/liste',
            name: 'Liste des cnss',
            component: () => import('@/views/Cnss/cnss.vue'),
          },
          {
            path: '/cnss/nouveau',
            name: 'Nouvelle cnss',
            component: () => import('@/views/Cnss/cnss-new.vue'),
          },
          {
            path: '/cnss/:id/edit',
            name: 'Editer cnss',
            component: () => import('@/views/Cnss/cnss-new.vue'),
          },
        ],
      },

      // fiche
      {
        path: '/fiche',
        name: 'Fiche',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/fiche/liste',
        children: [
          {
            path: '/fiche/liste',
            name: 'Liste des fiches',
            component: () => import('@/views/Fiche/fiches.vue'),
          },
          {
            path: '/fiche/:id/edit',
            name: 'Editer une fiche',
            component: () => import('@/views/Fiche/fiche-new.vue'),
          },
          {
            path: '/fiche/:id',
            name: "Détails d'une fiche",
            component: () => import('@/views/Fiche/fiche-details.vue'),
          },
          {
            path: '/fiche/:id/generer',
            name: 'Générer une fiche',
            component: () => import('@/views/Fiche/fiche-new.vue'),
          },
          {
            path: '/fiche/:id/dupliquer',
            name: 'Dupliquer une fiche',
            component: () => import('@/views/Fiche/fiche-new.vue'),
          },
        ],
      },
      // Rapports
      {
        path: '/rapport',
        name: 'Rapport',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/rapport/livre-de-paie',
        children: [
          {
            path: '/rapport/livre-de-paie',
            name: 'Livre de paie',
            component: () => import('@/views/Rapports/LivreDePaie.vue'),
          },
          {
            path: '/rapport/charges-sociales',
            name: 'Suivi des charges sociales',
            component: () => import('@/views/Rapports/ChargeSociales.vue'),
          },
          {
            path: '/rapport/charges-fiscales',
            name: 'Suivi des charges fiscales',
            component: () => import('@/views/Rapports/ChargesFiscales.vue'),
          },
          {
            path: '/rapport/masse-salariale',
            name: 'Evolution de la masse salariale',
            component: () => import('@/views/Rapports/MasseSalariale.vue'),
          },
        ],
      },
    ],
  },
  {
    path: '/pages',
    redirect: '/pages/404',
    name: 'Pages',
    component: {
      render() {
        return h(resolveComponent('router-view'))
      },
    },
    children: [
      {
        path: '404',
        name: 'Page404',
        component: () => import('@/views/pages/Page404'),
      },
      {
        path: '500',
        name: 'Page500',
        component: () => import('@/views/pages/Page500'),
      },
      {
        path: '/login',
        name: 'Login',
        component: () => import('@/views/pages/Login.vue'),
      },
      {
        path: 'register',
        name: 'Register',
        component: () => import('@/views/pages/Register'),
      },
    ],
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

router.beforeEach((to, from, next) => {
  if (to.matched[0].name == 'Accueil') {
    authGuard()
  }
  next()
})

export default router
