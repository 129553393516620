<template>
  <CDropdown variant="nav-item">
    <CDropdownToggle placement="bottom-end" class="py-0" :caret="false">
      <CIcon icon="cil-user" /> {{ SetUserName() }}
    </CDropdownToggle>
    <CDropdownMenu class="pt-0">
      <CDropdownHeader
        component="h6"
        class="dropdown-header bg-light dark:bg-white dark:bg-opacity-10 py-2"
      >
        Votre compte
      </CDropdownHeader>
      <CDropdownItem @click="Profil()">
        <CIcon icon="cil-user" /> Mon compte
      </CDropdownItem>
      <CDropdownItem @click="Logout()">
        <CIcon icon="cil-lock-locked" /> Se déconnecter
      </CDropdownItem>
    </CDropdownMenu>
  </CDropdown>
</template>

<script>
import avatar from '@/assets/images/avatars/8.jpg'
import LoginService from '@/views/pages/services/LoginService'
export default {
  name: 'AppHeaderDropdownAccnt',
  methods: {
    SetUserName() {
      const user = JSON.parse(localStorage.getItem('user'))
      return user.nom + ' ' + user.prenom
    },
    Logout() {
      LoginService.logout()
      this.$router.push('/login')
    },

    Profil() {
      const id = JSON.parse(localStorage.getItem('user'))._id
      this.$router.push({ name: 'Profil', params: { id } })
    },
  },
  setup() {
    return {
      avatar: avatar,
      itemsCount: 42,
    }
  },
}
</script>
