import Axios from '@/Axios'

class LoginService {
  login(identifiants) {
    return Axios.post('/auth/signin', identifiants)
  }
  logout() {
    localStorage.clear()
  }
  saveToken = (hash) => {
    localStorage.setItem('hash', hash)
    localStorage.setItem('_timestamp', new Date())
  }
  getToken = () => {
    localStorage.getItem('hash')
  }
  isLogged = () => {
    let hash = localStorage.getItem('hash')
    return !!hash
  }
}

export default new LoginService()
