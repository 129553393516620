import axios from 'axios'

const Axios = axios.create({
  // baseURL: 'http://localhost:3000',
  baseURL: process.env.VUE_APP_BE_URL || 'http://localhost:3000',
  //'https://docs.euphorbiagroup.com/',
  headers: {
    'Content-type': 'application/json',
  },
})

export default Axios
