<template>
  <CFooter>
    <div>
      <span class="ms-1">Copyright &copy; 2024. Tous droits réservés</span>
    </div>
    <div class="ms-auto">
      <a href="https://euphorbiagroup.com/" target="_blank">Euphorbia Group</a>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'AppFooter',
}
</script>
